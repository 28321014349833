.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #f4f4f4;
}

.login-form {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form h2 {
  text-align: center;
}

.login-form label {
  display: block;
  margin-bottom: 10px;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box; /* Includes padding and border in the element's width and height */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.login-form button:hover {
  background-color: #0056b3;
}

.create-account-text {
  text-align: center;
  margin-top: 15px;
}

.create-account-text a {
  color: #007BFF;
  text-decoration: none;
}

