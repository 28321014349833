/* Tooltip.css */

.slot-name {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  transform: translate(0%, 0); /* Center the name below the slot */
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  transform: translate(0%,0%); /* Center the tooltip and move it above the slot */
  
  width: max-content;
}

/* Optional: Arrow pointing down from the tooltip */
.tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ddd transparent transparent transparent;
}

.tooltip input[type="text"],
.tooltip input[type="number"] {
 width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tooltip .button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tooltip button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.tooltip button[type="submit"] {
  background-color: #28a745; /* Green */
}

.tooltip button[type="button"] {
  background-color: #dc3545; /* Red */
}

.tooltip button:hover {
  opacity: 0.9;
}

.combobox-container {
  position: relative;
  width: 100%;
}

.combobox-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.combobox-options {
  position: absolute;
  top: calc(100% + 5px); /* Adjust this to move the dropdown slightly below the input */
  left: 0; /* Align dropdown to the left */
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%; /* Ensure dropdown matches the input width */
}

.combobox-option {
  padding: 8px;
  cursor: pointer;
  box-sizing: border-box;
}

.combobox-option:hover, .combobox-option[data-focus] {
  background-color: #e0e0e0;
}

.combobox-no-results {
  padding: 8px;
  color: #999;
  text-align: center;
}

